import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CoraPay.css";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { registerValidationSchema } from "../validation/registervalidation";
import { sendOtp } from "../../services/send-otp.service";
import { registerEndUser } from "../../services/reqister.service";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Registration = () => {
  const [showOtpField, setShowOtpField] = useState(false);
  const navigate = useNavigate();
  const [otpError, setOtpError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      otp: "",
      password: "",
    },
    validationSchema: registerValidationSchema(showOtpField),
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async () => {
      await handleSubmit();
    },
  });
  const handleSendOtp = async () => {
    try {
      if (!formik.values.phoneNumber) {
        alert("Please enter a valid phone number.");
        return;
      }

      await sendOtp(formik.values.phoneNumber, "USER_REGISTRATION");
      toast.success("Otp sent successfully");
      setShowOtpField(true);
      setOtpError(false);
    } catch (error) {
      if (error.response.data === "EXISTING_PHONE_NUMBER") {
        toast.warn("الرقم مسجل بالفعل");
      } else {
        toast.warn(error.message);
      }
    }
  };
  const handleSubmit = async () => {
    if (!showOtpField) {
      setOtpError(true);
      toast.warn("يرجى إرسال رمز التحقق قبل التسجيل.");
      return;
    }

    if (showOtpField && !formik.values.otp) {
      toast.warn("يرجى إدخال رمز التحقق قبل التسجيل.");
      return;
    }
    try {
      await registerEndUser(
        formik.values.fullName,
        formik.values.phoneNumber,
        formik.values.otp,
        formik.values.password
      );
      toast.success("تم التسجيل ببنجاح");
      navigate("/login");
    } catch (error) {
      toast.warning(error.message);
      return error;
    }
  };
  return (
    <div className="CoraPay">
      <Helmet>
        <title>التسجيل</title>
      </Helmet>

      <div className="CoraPayForm">
        <h2>حساب جديد</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="CoraPay-form-group">
            <label htmlFor="name">الاسم</label>
            <input
              type="text"
              id="name"
              name="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.fullName && formik.errors.fullName && (
              <div className="text-sm text-red-600">
                {formik.errors.fullName}
              </div>
            )}
          </div>
          <div className="CoraPay-form-group">
            <label htmlFor="phone">رقم الجوال</label>
            <input
              type="tel"
              id="phone"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="05"
              required
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className="text-sm text-red-600">
                {formik.errors.phoneNumber}
              </div>
            )}
            <button
              className="CoraPay-OtpButton"
              onClick={handleSendOtp}
              type="button"
              disabled={
                !!formik.errors.phoneNumber || !formik.values.phoneNumber
              }
            >
              إرسال رمز التحقق
            </button>
            {otpError && (
              <div className="text-sm text-red-600 mt-1">
                يجب إرسال رمز التحقق
              </div>
            )}
          </div>
          {showOtpField && (
            <div className="CoraPay-form-group">
              <label htmlFor="otp">رمز التحقق</label>
              <input
                type="text"
                id="otp"
                name="otp"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.otp && formik.errors.otp && (
                <div className="text-sm text-red-600">{formik.errors.otp}</div>
              )}
            </div>
          )}
          <div className="CoraPay-form-group">
            <label htmlFor="password">انشاء كلمة مرور</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? (
                <AiFillEyeInvisible className="text-[#5a38ff]" size={20} />
              ) : (
                <AiFillEye className="text-[#5a38ff]" size={20} />
              )}
            </span>
            {formik.touched.password && formik.errors.password && (
              <div className="text-sm text-red-600">
                {formik.errors.password}
              </div>
            )}
          </div>
          <button
            className="CoraPay-MainButton"
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
          >
            تسجيل
          </button>
          <button
            className="CoraPay-SecButton"
            onClick={() => navigate("/login")}
          >
            تسجيل دخول
          </button>
        </form>
      </div>
    </div>
  );
};

export default Registration;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import "./CoraPay.css";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { fetchPaymentGeneratorPrevLinks } from "../../services/fetch-generator-prev-links.service";
import { toast } from "react-toastify";
import { deactivatePaymentLink } from "../../services/deactive-payment-link.service";

const MainLayout = () => {
  const navigate = useNavigate();
  const [prevGeneratorLinksList, setPrevGeneratorLinksList] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(null); // Track visible dropdown

  const toggleDropdown = (id) => {
    setDropdownVisible((prev) => (prev === id ? null : id));
  };
  const closeDropdown = () => {
    setDropdownVisible(null);
  };

  const handleEdit = (id) => {
    console.log("Edit item:", id);
    closeDropdown();
  };

  const handleDelete = async (id) => {
    try {
      await deactivatePaymentLink(id);
      toast.success("تم الغاء تفعيل الرابط");
      fetchGeneratorPrevLinks();
      closeDropdown();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "حدث خطأ أثناء الغاء تفعيل الرابط.";
      toast.error(errorMessage);
    }
  };

  const handleCopyLink = (link_url) => {
    navigator.clipboard
      .writeText(link_url)
      .then(() => {
        toast.success("تم نسخ الرابط بنجاح!");
      })
      .catch(() => {
        toast.error("حدث خطأ أثناء نسخ الرابط.");
      });
  };

  const handleCreateLink = () => {
    navigate("/Create-link"); // Navigate to the Create-link page
  };
  const fetchGeneratorPrevLinks = async () => {
    try {
      const response = await fetchPaymentGeneratorPrevLinks();
      setPrevGeneratorLinksList(response.links);
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    // Close dropdown on clicking outside
    const handleOutsideClick = (e) => {
      if (!e.target.closest(".CoraPay-more")) {
        closeDropdown();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);
  useEffect(() => {
    fetchGeneratorPrevLinks();
  }, []);
  return (
    <div className="CoraPay-main">
      <Helmet>
        <title>الرئيسية</title>
      </Helmet>

      <div className="CoraPay-balance-card">
        <img
          src="/coralogo.png" // Replace with PayPal logo
          alt="PayPal"
          className="CoraPay-balance-logo"
        />
        <div>
          <h3>0,00 SAR</h3>
          <p>الرصيد الاجمالي من المدفوعات</p>
        </div>
      </div>

      <div className="CoraPay-button-container">
        <button className="CoraPay-link-btn">
          <img
            src="/file-transfer.png"
            alt="تحويل سريع"
            className="CoraPay-btn-icon"
          />
          تحويل سريع
        </button>
        <button className="CoraPay-link-btn" onClick={handleCreateLink}>
          <img
            src="/link.png"
            alt="انشاء رابط دفع"
            className="CoraPay-btn-icon"
          />
          انشاء رابط دفع
        </button>
      </div>

      <label className="CoraPay-label">العمليات</label>

      <ul className="CoraPay-list">
        {prevGeneratorLinksList.map((item) => (
          <li key={item.link_id} className="CoraPay-list-item">
            <div className="CoraPay-item-left">
              <button
                onClick={() => handleCopyLink(item.link_url)}
                className="CoraPay-copy-btn"
              >
                <img src="/copy.png" alt="Copy Link" className="CoraPay-icon" />
              </button>
              <p
                className={`CoraPay-item-status ${
                  item.link_status === "ACTIVE"
                    ? "active"
                    : item.link_status === "INACTIVE"
                    ? "canceled"
                    : ""
                }`}
              >
                {item.link_status}
              </p>
            </div>

            <div className="CoraPay-item-right">
              <div className="CoraPay-item-details">
                <h4>{item.title}</h4>
              </div>
              <div className="CoraPay-more">
                <button
                  onClick={() => {
                    toggleDropdown(item.link_id);
                  }}
                  className="CoraPay-dots"
                >
                  ⋮
                </button>
                <div
                  className={`CoraPay-dropdown ${
                    dropdownVisible === item.link_id ? "visible" : ""
                  }`}
                >
                  <button
                    className="CoraPay-edit-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(item.link_id);
                    }}
                  >
                    تعديل
                  </button>
                  <button
                    className="CoraPay-cancel-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(item.link_id);
                    }}
                  >
                    الغاء
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <Footer />
    </div>
  );
};

export default MainLayout;
